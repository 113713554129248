var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',{attrs:{"title":"Periyodik Borçlandırma Tanımları","icon":"mdi-cube-unfolded","add-route":_vm.can('edit-periodical-income')
              ? { name: 'definitions.periodical-assessments.create' }
              : null,"hide-edit":!_vm.can('edit-periodical-income'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query,"show-delete":_vm.can('delete-periodical-income'),"delete-enabled":_vm.selectedItems.length === 1},on:{"clear-filters":_vm.$refs.headSearch.reset,"click:edit":_vm.handleEditClick,"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:delete":_vm.handleDeleteClick}}):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'definitions.periodical-assessments.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.assessment_type",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'definitions.periodical-assessments.show',
            params: { id: item.id },
          }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.is_active",fn:function({ item }){return [_c('rs-table-cell-boolean',{attrs:{"value":item.is_active}})]}},{key:"item.amount_per_house",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.ends_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.deferment_calculation_period",fn:function({ value }){return [_vm._v(" "+_vm._s(value === "daily" ? "Günlük" : "Aylık")+" ")]}},{key:"item.description",fn:function({ value }){return [_c('rs-table-cell-string',{attrs:{"limit":"20","value":value}})]}}],null,false,3515466554),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false)),_c('rs-confirm',{ref:"confirmDelete",attrs:{"loading":_vm.isLoading},on:{"confirmed":_vm.handleDelete,"cancelled":function($event){return _vm.$refs.confirmDelete.hide()}}})],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }