<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Periyodik Borçlandırma Tanımları"
            icon="mdi-cube-unfolded"
            :add-route="
              can('edit-periodical-income')
                ? { name: 'definitions.periodical-assessments.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @click:edit="handleEditClick"
            @reload="loadList"
            :hide-edit="!can('edit-periodical-income')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            :show-delete="can('delete-periodical-income')"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleDeleteClick"
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'definitions.periodical-assessments.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.assessment_type="{ item, value }">
          <router-link
            :to="{
              name: 'definitions.periodical-assessments.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ item }">
          <rs-table-cell-boolean :value="item.is_active" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_per_house="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.ends_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.deferment_calculation_period="{ value }">
          {{ value === "daily" ? "Günlük" : "Aylık" }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string limit="20" :value="value" />
        </template>
      </v-data-table>

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDelete"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasPermissions],
  computed: {
    ...mapGetters([
      "clusterId",
      "incomeTypeList",
      "projects",
      "debitorTypeList",
      "autoAssessmentTimingTypeList",
    ]),
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-periodical-income"),
        },
        {
          text: "Tahakkuk Türü",
          value: "assessment_type",
          searchable: "select",
          options: () => [
            { id: "Genel", name: "Genel" },
            { id: "BB Tipi", name: "BB Tipi" },
            { id: "Serbest", name: "Serbest" },
          ],
          width: "140px",
        },
        {
          text: "Aktif",
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
        {
          text: "Tahakkuk Zamanlama Tipi",
          value: "issue_auto_assessment_timing_type",
          searchable: "select",
          options: () => this.autoAssessmentTimingTypeList,
          width: "140px",
        },
        {
          text: "Son Ödeme Zamanlama Tipi",
          value: "last_payment_auto_assessment_timing_type",
          searchable: "select",
          options: () => this.autoAssessmentTimingTypeList,
          width: "140px",
        },
        {
          text: "Geçerlilik Bitiş Tarihi",
          value: "ends_on",
          searchable: "date",
          width: "140px",
        },
        {
          text: "Borçlandırma Nedeni",
          value: "income_type",
          searchable: "select",
          options: () => this.incomeTypeList,
        },
        {
          text: "Borçlu Tipi",
          value: "debitor_type",
          searchable: "select",
          options: () => this.debitorTypeList,
        },
        {
          text: "Borç Tutar",
          value: "amount_per_house",
          searchable: "number",
          align: "end",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Gecikme Hesaplama Tipi",
          value: "deferment_calculation_period",
          searchable: "select",
          options: () => [
            { id: "daily", name: "Günlük" },
            { id: "monthly", name: "Aylık" },
          ],
          width: "140px",
        },
        {
          text: "Gecikme Oranı(Aylık%)",
          value: "deferment_interest_percent",
          searchable: "number",
        },
        {
          text: "Gecikme Opsiyon(Gün)",
          value: "deferment_grace_period",
          searchable: "number",
        },
        {
          text: "Proje",
          value: "project",
          searchable: "select",
          options: () => this.projects,
        },
      ],
      options: {
        sortBy: ["updated_at"],
        sortDesc: [true],
      },
    };
  },
  methods: {
    handleEditClick() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "incomes.houses.show",
        params: {
          id: this.selectedItems[0].house_id,
        },
      });
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = [this.clusterId];
      params.type = "cluster";

      this.$api
        .query("income/periodical-assessments", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`income/periodical-assessments/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Periyodik borçlandırma silindi.");
          this.selectedItems = [];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        "Periyodik borçlandırmayı silmek istediğinizden emin misiniz?"
      );
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
